<!--
 * @Author: lbh
 * @Date: 2022-01-10 15:50:11
 * @LastEditors: lbh
 * @LastEditTime: 2023-12-08 10:51:11
 * @Description: file content
-->
<template>
  <div class="home-box">
    <selfHeader :configs="headerData.configs" />
    <!-- 頂部編輯欄目 -->
    <div
      class="px-top-setting"
      v-if="isLogin && 1==2"
    >
      <div class="welcome">歡迎登錄</div>
      <div
        class="save"
        @click="update"
      >編輯</div>
      <div
        class="logout"
        @click="onMenu"
      >回主頁</div>
    </div>
    <div class="cot-box">
      <component
        v-for="(item, index) in coms"
        :key="index"
        :is="item.component"
        :configs="item.configs"
        :Xindex="index"
        :lang="nowL.code"
      ></component>
    </div>
    <selfFooter :configs="footerData.configs" />
  </div>
</template>

<script>

// 導入編輯組件
import { getHeaderOrFooterList, getPageConfigList } from '@/js/api/api';
import selfHeader from '@/components/header.vue';
import storage from '@/js/utils/storage';
import selfFooter from '@/components/footer.vue';
export default {
  data () {
    return {
      pageName: '',
      coms: [],
      isLogin: false,
      headerData: {
        configs: {
          logo: '',
          menus: [],
        },
      },
      footerData: {
        configs: {
          footerText: '',
          menus: [],
        },
      },
      nowL: {
        code: 'HK',
        name: '繁體'
      }
    };
  },
  components: {
    selfHeader,
    selfFooter,
  },
  watch: {
    $route (o, n) {
      window.scrollTo(0, 0);
      console.log(this.$router);
      this.pageName = this.getPageName();
      this.initWindow();
    },
  },
  created () {
    this.initLang();
    this.$changeLang.set('home', this.initLang)
    this.pageName = this.getPageName();
    this.isLogin = this.$store.getters.getIsLogin;
    this.initWindow();
    this.getHeaderOrFooterListFun(1);
    this.getHeaderOrFooterListFun(2);
  },
  methods: {
    initLang () {
      // let language = this.$storage.get('language')

      this.$idb.get('language').then(language => {
        let now_language = this.$storage.get('now_language')
        if (!now_language) now_language = 'HK';
        for (let i = 0; i < language.length; i += 1) {
          if (language[i].code == now_language) {
            this.nowL = language[i]
            break;
          }
        }
      })


    },
    initWindow () {
      // 適配 老的 官網
      if (location.pathname.includes('/payme')) {
        location.replace('https://www.gingersoft.com.hk/');
      } else if (location.pathname.includes('/gsq')) {
        location.replace('https://www.gingersoft.com.hk/排乜Queue');
      } else this.getPageConfigListFun();
    },
    getPageName () {
      if (this.$route.path.includes('products')) {
        return `${this.$route.params.arg}`;
      } else if (this.$route.path.includes('agreement')) {
        return 'agreement';
      } else {
        return this.$route.params.arg;
      }
    },
    update () {
      this.$store.commit('setIsEdit', true);
      this.$router.replace({
        name: 'edit',
        params: {
          arg: this.pageName || '首頁',
        },
      });
    },
    onMenu () {
      this.$router.replace({
        name: 'menu',
      });
    },
    getPageConfigListFun () {
      let pageName = this.pageName;
      if (pageName == 'agreement') {
        let arg = this.$route.params.arg;
        let index = this.$util.base64.atob(arg);
        this.coms = [];
        let coms = {};
        coms.key = 'agreement';
        coms.configs = {};
        coms.configs.index = index;
        let footerData = this.footerData;
        if (footerData.configs && footerData.configs.agreement) {
          let agree = footerData.configs.agreement[coms.configs.index];
          coms.configs = agree;
        }
        coms.configs.index = index;
        // coms.configs.title = '';
        // coms.configs.content = '';
        this.getComponent(coms);
      } else {
        this.coms = [];
        getPageConfigList({
          brandId: 1,
          pageName: this.pageName || '首頁',
        })
          .then((res) => {
            if (res.success && res.data) {
              let data = res.data;
              document.title = `Gingersoft薑軟件有限公司 - ${data.pageTitle || '雲端餐飲管理系統POS'}`;
              let oldMeta = document.querySelector('meta[name=description]');
              if (oldMeta) oldMeta.remove();
              let meta = document.createElement('meta');
              meta.name = 'description'
              meta.dataHid = 'description'
              meta.content = `Gingersoft RicePOS 雲端餐飲管理系統，支持不同設備數據同步，只需一部手機即可操作，方便快捷。整合餐飲管理、手機落單和會員系統，助你輕鬆管理餐廳運作，提升營運成效。${data.pageDescription}`
              document.querySelector('title').after(meta)
              let keywords = document.createElement('meta');
              keywords.name = 'keywords'
              keywords.content = `飯糰,掃碼點餐,GingerSoft,gingersoft,POS,RicePOS,ricepon,ricepos,Ricepon,${data.pageKeyWords}`;
              document.querySelector('title').after(keywords)

              let pageComs = window.location.href.includes('/preview/') ? data.pageValueDraft : data.pageValue;
              if (pageComs) {
                let coms = JSON.parse(pageComs);
                for (let i in coms) {
                  if (coms[i])
                    this.getComponent(coms[i]);
                }
              }
            } else {
              this.$router.replace({
                name: '404',
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$router.replace({
              name: '404',
            });
          });
      }
    },
    // 獲取 組件
    getComponent (event) {
      let coms = this.coms;
      let com = event;
      event.component = () => import(`../components/do/${event.key}.vue`);
      coms.push(com);
      this.coms = coms;
    },
    getHeaderOrFooterListFun (type) {
      let params = {};
      params.brandId = 1;
      params.type = type;
      params.pageIndex = 0;
      params.pageSize = 10;
      getHeaderOrFooterList(params)
        .then((res) => {
          let list = res.data.list[0] || 0;
          if (type == 1) {
            if (Array.isArray(list) == false) {
              storage.set('headerData', list.configValue);
              this.headerId = list.id;
              this.headerData = JSON.parse(list.configValue);
            }
          } else if (type == 2) {
            if (Array.isArray(list) == false) {
              storage.set('footerData', list.configValue);
              this.footerId = list.id;
              let footerData = JSON.parse(list.configValue);
              this.footerData = footerData;
              let pageName = this.pageName;
              if (pageName == 'agreement') {
                let coms = this.coms[0];
                let agreement = footerData.configs.agreement || [];
                let agree = agreement[coms.configs.index];
                agree = this.$util.object.clone(agree);
                coms.configs.title = agree.title;
                coms.configs.content = agree.content;
                this.coms[0] = coms;
                this.$forceUpdate();
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.home-box {
  .cot-box {
    min-height: 100vh;
  }
  .px-top-setting {
    height: 30px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #ededed;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 10px;
    div {
      &.save {
        margin-left: auto;
        cursor: pointer;
        &:hover {
          color: var(--themeColor);
        }
      }
      &.logout {
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: var(--themeColor);
        }
      }
    }
  }
}
</style>
